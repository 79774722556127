import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Papinotas`}</h1>
    <p>{`After graduating from university in 2016, I joined Papinotas as the CTO. Papinotas was a Ed-tech startup that started as a platform that allowed school teachers and administratives to communicate with parents. After I left, the company pivoted to a platform that managed many aspects of the school, such as attendance, grades and communication with parents. The company grew a lot during my time there. I started as the head of a team of other 6 engineers and left when the team grew to around 25 people.`}</p>
    <h2>{`Technical challenges`}</h2>
    <p>{`When I joined, the software was a monolith written in Ruby on Rails. This was the second version of the platform, since the first went down after the first CTO left the company. This second version was built in a hurry. It was working in less than two weeks and finished in a month. Given the technical debt and the compromises made to get the product out, the platform was not scalable, was hard to maintain and had a lot of bugs. The company was growing fast and the platform was not able to handle the load.`}</p>
    <p>{`We decided to rewrite the platform from scratch. We used Ruby on Rails for the backend (as the team was already familiar with the framework), and React for the frontend. We also decided to use a combination of REST and GraphQL as the API layer. Around this time, the focus of the company expanded to other products beyond communication, so we decided to split the platform into services. This allowed us to scale each service independently and to have a more modular architecture.`}</p>
    <h2>{`Personal growth`}</h2>
    <p>{`During my time at Papinotas, I learned a lot about how to manage a team. I learned how to hire, how to give feedback, how to manage people, how to set goals and how to motivate people. Previously I had worked alone or in small teams, but during this time I was forced to delegate tasks and eventually to form sub-teams with strong leaders and decoupled responsibilities.`}</p>
    <h2>{`My decision to leave`}</h2>
    <p>{`After two years working in Papinotas, I had two things in mind. First, the team had grown a lot and each day I was spending more time managing people than coding. I enjoyed that role, but my technical itch was not being scratched. Second, I was investing a lot of time learning about crypto and blockchain, and I wanted to work on a project that was related to that. I decided to leave Papinotas and start working on a blockchain project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      